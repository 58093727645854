/*!

=========================================================
* BLK Design System PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

//require('dotenv').config();

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.2.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

// presentation pages
import Index from "views/Index.js";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleReCaptchaProvider reCaptchaKey="6Lf0RHAqAAAAANtJVhsvxrgavYyD51vFv4NL3ge5">
  <BrowserRouter>
    <Routes>      
      <Route path="/" element={<Index />} />
    </Routes>
  </BrowserRouter>
  </GoogleReCaptchaProvider>
);
