/*!

=========================================================
* BLK Design System PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
//  Button,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
 // NavItem,
  Nav,
  Container,
  Row,
  Col,
 // UncontrolledTooltip,
} from "reactstrap";

export default function ColorNavbar(props) {
  const scrollSection = (e,sectionname) => {
    e.preventDefault();
    console.log(`scrollSection Called scrollsection:${sectionname}`);
    document.getElementById(sectionname).scrollIntoView();
  };
  // const [scrollsection, setScrollSection] = useState("logosection");
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {

      setNavbarColor((props && props.scrollColor) ? props.scrollColor : "bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };
  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
              {/* <span>PEELS.</span> */}
              <img alt="..." className="img-fluid" src={require("assets/img/logo.png")} style={{height:'40px'} } />
            </NavbarBrand>
            {/* <UncontrolledTooltip delay={0} target="tooltip6619950104">
              Designed and Coded by Creative Tim
            </UncontrolledTooltip> */}
            <button className="navbar-toggler text-primary" id="navigation">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <UncontrolledCollapse navbar toggler="#navigation">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {/* BLK• <span>PRO React</span> */}
                    PEELS.                  
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" id="navigation">
                    <i className="tim-icons icon-simple-remove text-primary" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>              
              <UncontrolledDropdown nav>
                <DropdownToggle nav color="" onClick={(e) => { scrollSection(e, "businessofpeels"); }} tag={Link}>
                  <i aria-hidden={true} className="tim-icons icon-paper" />  
                  <p>Business of Peels</p>  
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav color="default" onClick={(e) => { scrollSection(e, "contactus"); }} tag={Link}>
                  <i aria-hidden={true} className="tim-icons icon-square-pin" />  
                  <p>Contact Us</p>  
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle caret color="default" nav>
                  {/* <i className="fa fa-cogs d-lg-none d-xl-none" /> */}
                  <i className="tim-icons icon-bullet-list-67" /> 
                  <p>More</p> 
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  {/* <DropdownItem onClick={(e) => { scrollSection(e, "businessofpeels"); }} tag={Link}>
                    <i className="tim-icons icon-paper" />
                    Busienss of Peels
                  </DropdownItem> */}
                  <DropdownItem onClick={(e) => { scrollSection(e, "tokenomics"); }} tag={Link}>
                    <i className="tim-icons icon-chart-pie-36" />
                    Tokenomics
                  </DropdownItem>
                  <DropdownItem onClick={(e) => { scrollSection(e, "marketplaces"); }} tag={Link}>
                    <i className="tim-icons icon-chart-bar-32" />
                    Marketplace Comparisons
                  </DropdownItem>
                  <DropdownItem onClick={(e) => { scrollSection(e, "marketgrowth"); }} tag={Link}>
                    <i className="tim-icons icon-globe-2" />
                    Market growth 
                  </DropdownItem>
                  <DropdownItem onClick={(e) => { scrollSection(e, "partners"); }} tag={Link}>
                    <i className="tim-icons icon-single-02" />
                    Our  Partners 
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>              
              {/* <NavItem>
                <Button
                  className="nav-link"
                  color="default"
                  href="https://www.creative-tim.com/product/blk-design-system-pro-react?reaf=blkdspr-pages-navbar"
                  size="sm"
                  target="_blank"
                >
                  <p>Buy Now</p>
                </Button>
              </NavItem> */}
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}
