import React from "react";
// ReactJS plugin for a nice carousel
import Slick from "react-slick";
// reactstrap components
import { Row, Col, Button, Container } from "reactstrap";

// custom previous button for the slick component
const PrevButton = props => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = props => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};

let slickSettings = {
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  className: "light slider blog-post",
  dots: false,
  infinite: true,
  centerMode: true,  
  autoplay: true,
  autoplaySpeed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
}

class SlickCarousel extends React.Component {
  render() {
    return (
      <>
      <Container className="mt-5 mb-5" id="partners">    
      <div className="text-center">
          <h1 className="title text-center text-primary">Our Partners</h1>
        </div>  
      <Row className="">
        
        <Col md="12">
          {/* <section className="light slider" /> */}
          <Slick {...slickSettings} style={{alignItem:'center'}}>
          {/* <div className="info ">
            <img alt="..." src={require("assets/img/partners_logo/disney.png")}  />
          </div> */}
          <div className="info ">
            <img alt="..." src={require("assets/img/partners_logo/badlab.png")}  />
          </div>
          <div className="info ">
            <img alt="..." src={require("assets/img/partners_logo/samsung.png")}  />
          </div>
          <div className="info ">
           <img alt="..." src={require("assets/img/partners_logo/kfc.png")}  />
          </div>     
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/puma.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/oppo.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/pangeaseed.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/redbull.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/mattel.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/jbl.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/ripcurl.png")} />
      </div>
      {/* <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/warnerbros.png")} />
      </div> */}
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/mevius.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/tealive.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/footlocker.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/lofficel.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/kipling.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/newera.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/warnermusic.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/universalmusic.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/urbanscape.png")} />
      </div>
      {/* <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/bearbrick.png")} />
      </div> */}
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/realme.png")} />
      </div>
      {/* <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/angrybirds.png")} />
      </div> */}
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/newbalance.png")} />
      </div>
      {/* <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/rovio.png")} />
      </div> */}
      {/* <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/sega.png")} />
      </div> */}
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/toms.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/ronenglish.png")} />
      </div>
      <div className="info ">
        <img alt="..." src={require("assets/img/partners_logo/marshall.png")} />
      </div>
      <div className="info ">
        <img alt="..." src={require("assets/img/partners_logo/hundred100.png")} />
      </div> 
      <div className="info ">
       <img alt="..." src={require("assets/img/partners_logo/wwf.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/grandturismo.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/gyeonggimuseum.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/hiddenspace.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/logocopy.png")} />
      </div>
      {/* <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/mastersoftheuniverse.png")} />
      </div> */}
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/meetingofstyles.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/moypublications.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/mtncolours.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/nationalartgallery.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/onitsukatiger.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/sonymusic.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/petronas.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/prestige.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/sneakerlah.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/sothebys.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/stickybox.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/tatlerAsia.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/tgv.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/tiger.png")} />
      </div>
      {/* <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/tomandjerry.png")} />
      </div> */}
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/vans.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/wadezig.png")} />
      </div>
      <div className="info ">
      <img alt="..." src={require("assets/img/partners_logo/sonypictures.png")} height={'200px'} />
      </div>
      <div className="info ">
        <img alt="..." src={require("assets/img/partners_logo/dc.png")} />
      </div>
      
          </Slick>
        </Col>
      </Row>
      </Container>
      </>
    );
  }
}

export default SlickCarousel;