/*!

=========================================================
* BLK Design System PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert
} from "reactstrap";

export default function ContactUs() {

  //  const [lastNameContact1Focus, setLastNameContact1Focus] = React.useState(false);

   const [emailContactFocus, setEmailContactFocus] = React.useState(false);
   const [firstNameContactFocus, setFirstNameContactFocus] = React.useState(false);
   const [lastNameContactFocus, setLastNameContactFocus] = React.useState(false);

  const [loginData, setdata] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
    userMsg: "",
    "g-recaptcha-response": "",
  });

  //const [captchaToken, setCaptchaToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isLoading, setIsLoading] = useState(false);
  const [responseMsg, setResponseMsg] = useState('');
  const [alertType, setAlertType] = useState('info');
  //const navigate = useNavigate();

  const submitHandler = async () => {

    setIsLoading(true);

    //   if (!captchaToken) {
    //     alert("Please complete the reCAPTCHA verification.");
    //     setIsLoading(false);
    //     return;
    // }

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    loginData["g-recaptcha-response"] = await executeRecaptcha('click'); // Specify an action name

    // Send token to backend for verification
    // await fetch('/api/verify-recaptcha', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ token }),
    // });

    if (!loginData.firstName) {
     // alert('Please enter your First Name!');
      setResponseMsg('Please enter your First Name!');
      setAlertType('warning');
      setIsLoading(false);
      return;
    }
    if (!loginData.userEmail) {
     // alert('Please enter Email Id!');
      setResponseMsg('Please enter Email Id!');
      setAlertType('warning');
      setIsLoading(false);
      return;
    }
    if (!loginData.userMsg) {
      setResponseMsg('Please enter your message!');
      setAlertType('warning');
      setIsLoading(false);
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(loginData.userEmail)) {
      setResponseMsg('Please enter a valid email.');
      setAlertType('warning');
      setIsLoading(false);
      return;
    }

    if (loginData.userMsg.length < 10) {
      setResponseMsg('Message must contain atleast 10 characters.');
      setAlertType('warning');
      setIsLoading(false);
      return;
    }

    //const postUrl = "/sendmail";
    const postUrl = "https://cvrmiz0hnf.execute-api.ap-southeast-1.amazonaws.com/sendemail"; //"http://localhost:5000/api/sendmail"; // http://localhost:3000/upload

    // console.log(`myData: ${loginData.firstName}`);

    try {
      const response = await fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });
      const data = await response.json();
      console.log('Response from server:', data);
      
      setIsLoading(false);

      if (data.rescode === 200) {
        console.log(data.message);
        setResponseMsg(data.message);
        setAlertType('success');
        setdata({
          firstName: "",
          lastName: "",
          userEmail: "",
          userMsg: "",
        });

        // setCaptchaToken(null); 
        setIsLoading(false);
        // window.location.reload();
      }
      else {
        setResponseMsg(data.message);
        setAlertType('warning');
      }

    } catch (error) {
      console.error(`Error: ${error}`);
      
      setResponseMsg('Error!');
      setAlertType('primary');
      setIsLoading(false);
    }

  }

  return (
    <>
      <div className="cd-section" id="contactus">

        {/* ********* CONTACT US 4 ********* */}
        <div className="contactus-4">
          {/* <ContactUsMap /> */}
          <Container>
            <Row>
              <Col md="5">
                <h1 className="title text-primary">Get in Touch</h1>
                <h4 className="description">
                  Do you need more information? Please contact us to find more
                  about our products and services.
                </h4>
              </Col>
              <Col className="m-auto" md="12">
                <Card className="card-contact card-raised bg-dark">
                  <Row>
                    <Col md="8">
                      <Form
                        className="p-3"
                        id="contact-form-4"
                        method="post"
                        role="form"
                      >
                        <CardHeader>
                          <CardTitle tag="h4">Send us a message</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <label>First name</label>                       

                              <InputGroup className={classnames({ "input-group-focus": firstNameContactFocus })} >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText><i className="tim-icons icon-single-02" /> </InputGroupText>
                                </InputGroupAddon>
                                <Input aria-label="First Name..." placeholder="First Name..." type="text" onChange={(e) => setdata({ ...loginData, firstName: e.target.value })} value={loginData.firstName} onFocus={(e) => setFirstNameContactFocus(true)} onBlur={(e) => setFirstNameContactFocus(false)} />
                              </InputGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="">
                                <label>Last name</label>
                                <InputGroup className={classnames({ "input-group-focus": lastNameContactFocus })} >
                                  <InputGroupAddon addonType="prepend">
                                    {/* <InputGroupText> <i className="tim-icons icon-caps-small" /></InputGroupText> */}
                                    <InputGroupText><i className="tim-icons icon-single-02" /> </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    aria-label="Last Name..."
                                    placeholder="Last Name..."
                                    type="text"
                                    onChange={(e) => setdata({ ...loginData, lastName: e.target.value })}
                                    value={loginData.lastName}
                                    onFocus={(e) => setLastNameContactFocus(true)} onBlur={(e) => setLastNameContactFocus(false)}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            <label>Email address</label>
                            <InputGroup className={classnames({ "input-group-focus": emailContactFocus })} >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="tim-icons icon-email-85" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email Here..."
                                type="email"
                                id="userEmail"
                                name="userEmail"
                                onChange={(e) => setdata({ ...loginData, userEmail: e.target.value })}
                                value={loginData.userEmail}                                
                                onFocus={(e) => setEmailContactFocus(true)} onBlur={(e) => setEmailContactFocus(false)}
                             
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <label>Your message</label>
                            <Input
                              id="message-3"
                              name="userMsg"
                              rows="6"
                              type="textarea"
                              onChange={(e) => setdata({ ...loginData, userMsg: e.target.value })}
                              value={loginData.userMsg}
                            />
                          </FormGroup>
                          <Row>
                            <Col md="6">{ responseMsg?  <UncontrolledAlert color={alertType}>{responseMsg}</UncontrolledAlert> : '' }</Col>
                            <Col md="6">
                              <Button
                                className="btn-round pull-right"
                                color="primary"
                                onClick={submitHandler}
                                disabled={isLoading}
                              >
                                {isLoading ? 'Sending...' : 'Send Message'}
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Col>
                    <Col md="4">
                      <div className="info text-left bg-primary">
                        <CardTitle tag="h4">Contact information</CardTitle>
                        <div className="info info-horizontal mt-4">
                          <div className="icon">
                            <i className="tim-icons icon-square-pin" />
                          </div>
                          <div className="description">
                            <h5 className="info-title">
                              Kuala Lumpur <br />
                             Malaysia
                            </h5>
                          </div>
                        </div>
                        {/* <div className="info info-horizontal mt-4">
                          <div className="icon">
                            <i className="tim-icons icon-mobile" />
                          </div>
                          <div className="description">
                            <h5 className="info-title">+16(3412) 421 241</h5>
                          </div>
                        </div> */}
                        <div className="info info-horizontal mb-3">
                          <div className="icon">
                            <i className="tim-icons icon-email-85" />
                          </div>
                          <div className="description">
                            
                            <a href="mailto:info@4-stages.com" target="_blank" rel="noreferrer"><h5 className="info-title">info@4-stages.com </h5></a>
                           
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END CONTACT US 4 ********* */}
      </div>{" "}
    </>
  );
}
